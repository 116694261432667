<template>
    <div>
        <h1>Warehouse Order</h1>

        <div v-for="id in trackers" v-bind:key="id">
            Order created #<router-link :to="`/tracker/${ id }`">{{ id }}</router-link>
        </div>

        <div class="row spacing_top">
            <div class='col-md-3 col-sm-4 col-xs-6'>
                <label>Item</label>
                <!-- TODO need to clear this out on submission -->
                <Typeahead v-bind:ID.sync="formFields.curbID" cdi_type="curb" />
            </div>
        </div>

        <table class="table spacing_top">
            <thead>
                <tr>
                    <th>Location</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">
                    <td>{{ cdiLocation }}</td>
                    <td>
                        <input v-model="formFields.cdiLocations[cdiLocationID]" class="form-control" type="number" min="0" />
                    </td>
                </tr>
            </tbody>
        </table>

        <button class="btn btn-default" @click="this.order">
            Place order
        </button>
    </div>
</template>

<script>
    import { store } from '@/store/Tool.store.js'
    import Typeahead from "@/components/utils/typeaheads/Typeahead.vue";
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';

    export default {
        name: "WarehouseOrder",
        data() {
            return {
                cache: {
                    cdiLocationCache
                },
                formFields: {
                    curbID: 0,
                    cdiLocations: []
                },
                trackers: []
            }
        },
        components: {Typeahead},
        methods: {
            order: function () {
                store.api('/tracker/place_warehouse_orders/', this.buildQueryString()).then(results => this.trackers = results.trackers);
                this.formFields.curbID = 0;
                this.formFields.cdiLocations = [];
            },
            buildQueryString: function () {
                let queryString = {
                    'params[curb_id]': this.formFields.curbID
                };
                this.formFields.cdiLocations.forEach((number, locationID) => queryString[`params[locations][${locationID}]`] = number);

                return queryString;
            }
        }
    }
</script>

<style scoped>

</style>